// Gatsby supports TypeScript natively!
import React, { Fragment } from 'react';
import { PageProps, Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

type Data = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
  allBlogPost: {
    nodes: {
      slug: string;
      date: string;
      coverImage?: {
        childImageSharp?: {
          gatsbyImageData: string
        }
      };
      parent: {
        excerpt: string;
        frontmatter: {
          title?: string;
          imageCredit?: {
            description?: string
          }
        };
      };
    }[];
  };
}

function FluidImage({ image, description }) {
  if (!image?.childImageSharp?.gatsbyImageData) {
    return null;
  }

  return <GatsbyImage className="post-thumbnail" alt={description} image={image.childImageSharp.gatsbyImageData} />;
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allBlogPost.nodes;

  return (
    <Layout location={location} title={siteTitle} className="index-page">
      <SEO title="All posts" />
      {posts.map(({ slug, date, parent, coverImage }) => {
        const { excerpt, frontmatter } = parent;
        const title = frontmatter.title || slug;

        return (
          <Fragment  key={slug}>
          <article>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={slug}>
                  {title}
                </Link>
              </h3>
              <div><small>{date}</small></div>
              <Link style={{ boxShadow: `none` }} to={slug}>
                <FluidImage image={coverImage} description={frontmatter?.imageCredit?.description} />
              </Link>
            </header>
            <section dangerouslySetInnerHTML={{  __html: excerpt }} />
          </article>
          <hr />
          </Fragment>
        )
      })}
      <Bio />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allBlogPost(sort: {fields: date, order: DESC}) {
      nodes {
        slug
        date(formatString: "MMMM D, YYYY")
        coverImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 250)
          }
        }
        parent {
          ... on MarkdownRemark {
            frontmatter {
              title
              imageCredit: coverImage {
                description
              }
            }
            excerpt(pruneLength: 500, format: HTML)
          }
        }
      }
    }
  }
`
